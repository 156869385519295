<div class="container">
  <h3 *ngIf="configuration.showDate">
    {{ date | date: configuration.dateFormat }}
  </h3>
  <h1>
    {{ date | date: "h:mm" }}
    <span class="seconds" *ngIf="configuration.showSeconds">{{ date | date: "ss" }}</span>
    {{ date | date: "a" }}
  </h1>
</div>

<form [formGroup]="form">
  <mat-card>
    <mat-card-title>
      Login
    </mat-card-title>
    <mat-card-content>
      <mat-form-field appearance="standard">
        <mat-label>Email</mat-label>
        <input matInput formControlName="email" type="email" required />
        <mat-error *ngIf="form.get('email').hasError('required')">
          The email field is required.
        </mat-error>
        <mat-error *ngIf="form.get('email').hasError('email')">
          You must enter a valid email address.
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="standard">
        <mat-label>Password</mat-label>
        <input matInput formControlName="password" type="password" required />
        <mat-error *ngIf="form.get('password').hasError('required')">
          The password field is required.
        </mat-error>
      </mat-form-field>
    </mat-card-content>
    <mat-card-actions>
      <button mat-raised-button color="primary" (click)="onSubmit()">
        Login
      </button>
      <a
        mat-raised-button
        color="accent"
        [routerLink]="['/auth/forgot-password']"
      >
        Forgot password
      </a>
    </mat-card-actions>
  </mat-card>
</form>

<form [formGroup]="form">
  <mat-card>
    <mat-card-title>Create list</mat-card-title>
    <mat-card-content>
      <mat-form-field>
        <mat-label>Name</mat-label>
        <input matInput formControlName="name" type="text" required />
        <mat-error *ngIf="form.get('name').hasError('required')">
          The name field is required.
        </mat-error>
      </mat-form-field>
      <app-list-data formControlName='data'></app-list-data>
    </mat-card-content>
    <mat-card-actions>
      <button (click)="onSubmit()" mat-raised-button color="primary">
        Create
      </button>
      <a [routerLink]="['/list']" mat-flat-button color="link">
        Cancel
      </a>
    </mat-card-actions>
  </mat-card>
</form>

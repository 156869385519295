<a [routerLink]="['/users/invite']" mat-raised-button color="primary">
  <mat-icon>add</mat-icon>
  Invite User
</a>
<mat-form-field>
  <mat-label>Filter</mat-label>
  <input (change)="updateData()" matInput [formControl]="filter" />
</mat-form-field>
<mat-spinner *ngIf="!users"></mat-spinner>

<table mat-table [dataSource]="users" matSort (matSortChange)="onSort($event)" *ngIf="users">
  <ng-container matColumnDef="email">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
    <td mat-cell *matCellDef="let row">{{row.email}}</td>
  </ng-container>

  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
    <td mat-cell *matCellDef="let row">{{row.name}}</td>
  </ng-container>

  <ng-container matColumnDef="emailConfirmed">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Email Confirmed</th>
    <td mat-cell *matCellDef="let row">{{row.emailConfirmed ? 'Yes' : 'No'}}</td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="columns"></tr>
  <tr mat-row *matRowDef="let row; columns: columns" (click)="details(row)"></tr>
</table>

<mat-paginator [length]="count"
               (page)="onPage($event)"
               [pageSize]="20"
               [pageIndex]="page"
               *ngIf="users">
</mat-paginator>
<div [formGroup]="form">
  <div formArrayName="lists">
    <div *ngFor="let control of listArray.controls; let i = index">
      <mat-form-field class="list-select">
        <mat-label>List</mat-label>
        <mat-select [formControlName]="i" required>
          <mat-option *ngFor="let list of listOptions" [value]="list.id">{{list.name}}</mat-option>
        </mat-select>
        <mat-error *ngIf="control.hasError('required')">
          The list field is required.
        </mat-error>
      </mat-form-field>
      <button type="button" mat-mini-fab color="warn" *ngIf="i !== 0" (click)="deleteList(i)">
        <mat-icon>delete</mat-icon>
      </button>
    </div>
  </div>
  <button type="button" mat-raised-button color="primary" (click)="addList()">
    Add list
  </button>
</div>
<h1>Edit Row</h1>
<form [formGroup]="form">
  <div mat-dialog-content>
    <mat-form-field *ngFor="let column of data.columns">
      <mat-label>{{ column.name }}</mat-label>
      <input matInput [formControlName]="column.name" type="text" />
    </mat-form-field>
  </div>
  <div mat-dialog-actions>
    <button type="submit" mat-raised-button (click)="submit()" color="primary">Save</button>
    <button type="button" mat-flat-button (click)="cancel()">Cancel</button>
  </div>
</form>

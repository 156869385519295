<form [formGroup]="form">
  <mat-card *ngIf="data">
    <mat-card-title>Edit tile</mat-card-title>
    <mat-card-content>
      <mat-form-field>
        <mat-label>Type</mat-label>
        <mat-select formControlName="type" required>
          <mat-option *ngFor="let type of types" [value]="type">{{
            type
          }}</mat-option>
        </mat-select>
        <mat-error *ngIf="form.get('type').hasError('required')">
          The type field is required.
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Location</mat-label>
        <mat-select formControlName="location" required>
          <mat-option *ngFor="let location of locations" [value]="location">{{
            location
          }}</mat-option>
        </mat-select>
        <mat-error *ngIf="form.get('location').hasError('required')">
          The location field is required.
        </mat-error>
      </mat-form-field>
      <app-tile-configuration
        [type]="type"
        formControlName="configurationJson"
      ></app-tile-configuration>
    </mat-card-content>
    <mat-card-actions>
      <button (click)="onSubmit()" mat-raised-button color="primary">
        Save changes
      </button>
      <button (click)="onDelete()" mat-raised-button color="warn">
        Delete tile
      </button>
      <a
        [routerLink]="['/screen/edit']"
        [queryParams]="{ id: data.screenId }"
        mat-flat-button
        color="link"
      >
        Cancel
      </a>
    </mat-card-actions>
  </mat-card>
  <mat-spinner *ngIf="!data"></mat-spinner>
</form>

<form [formGroup]="form">
  <mat-card>
    <mat-card-title>
      Reset Password
    </mat-card-title>
    <mat-card-content>
      <mat-form-field appearance="standard">
        <mat-label>Email</mat-label>
        <input matInput formControlName="email" type="email" required />
        <mat-error *ngIf="form.get('email').hasError('required')">
          The email field is required.
        </mat-error>
        <mat-error *ngIf="form.get('email').hasError('email')">
          You must enter a valid email address.
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="standard">
        <mat-label>Password</mat-label>
        <input matInput formControlName="password" type="password" required />
        <mat-error *ngIf="form.get('password').hasError('required')">
          The password field is required.
        </mat-error>
        <mat-error
          *ngIf="
            !form.get('password').hasError('required') &&
            form.get('password').hasError('length')
          "
        >
          Your password must be at least 8 characters long
        </mat-error>
        <mat-error
          *ngIf="
            !form.get('password').hasError('required') &&
            form.get('password').hasError('uppercase')
          "
        >
          Your password must contain an uppercase character
        </mat-error>
        <mat-error
          *ngIf="
            !form.get('password').hasError('required') &&
            form.get('password').hasError('lowercase')
          "
        >
          Your password must contain a lowercase character
        </mat-error>
        <mat-error
          *ngIf="
            !form.get('password').hasError('required') &&
            form.get('password').hasError('number')
          "
        >
          Your password must contain a number
        </mat-error>
        <mat-error
          *ngIf="
            !form.get('password').hasError('required') &&
            form.get('password').hasError('symbol')
          "
        >
          Your password must contain a special character.
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="standard">
        <mat-label>Confirm password</mat-label>
        <input
          matInput
          formControlName="confirmPassword"
          type="password"
          required
        />
        <mat-error *ngIf="form.get('confirmPassword').hasError('required')">
          The confirm password field is required.
        </mat-error>
        <mat-error *ngIf="form.get('confirmPassword').hasError('equal')">
          Passwords must match.
        </mat-error>
      </mat-form-field>
    </mat-card-content>
    <mat-card-actions>
      <button mat-raised-button color="primary" (click)="onSubmit()">
        Reset password
      </button>
    </mat-card-actions>
  </mat-card>
</form>

<div class="site-container" [class.dark]="dark">
  <mat-toolbar color="primary" *ngIf="!fullscreen">
    <button
      fxHide="true"
      fxShow.lt-md="true"
      mat-icon-button
      class="example-icon"
      aria-label="Example icon-button with menu icon"
      (click)="isOpen = !isOpen"
    >
      <mat-icon>menu</mat-icon>
    </button>
    <span class="site-title">home.bscox.com</span>
    <a
      mat-button
      *ngFor="let link of links"
      fxShow="true"
      fxHide.lt-md="true"
      [routerLink]="[link.path]"
      [class.hidden]="!roles || !roles.includes(link.role)"
    >
      {{ link.text }}
    </a>
    <span class="spacer"></span>
    <span
      class="email"
      *ngIf="isAuthenticated"
      fxHide="true"
      fxShow.lg="true"
    >
      {{ email }}
    </span>
    <a
      class="non-tab-button"
      [routerLink]="['/auth/login']"
      mat-flat-button
      color="primary"
    >
      {{ isAuthenticated ? "Logout" : "Login" }}
    </a>
  </mat-toolbar>
  <mat-sidenav-container>
    <mat-sidenav mode="side" [opened]="isOpen">
      <mat-nav-list id="sidebar">
        <a
          mat-list-item
          *ngFor="let link of links"
          [routerLink]="[link.path]"
          (click)="isOpen = false"
          [class.hidden]="!roles || !roles.includes(link.role)"
        >
          {{ link.text }}
        </a>
      </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content [class.fullscreen]="fullscreen" [class.container]="container">
      <router-outlet></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>

<form [formGroup]="form">
  <mat-card>
    <mat-card-title>
      Forgot Password
    </mat-card-title>
    <mat-card-content>
      <mat-form-field appearance="standard">
        <mat-label>Email</mat-label>
        <input matInput formControlName="email" type="email" required />
        <mat-error *ngIf="form.get('email').hasError('required')">
          The email field is required.
        </mat-error>
        <mat-error *ngIf="form.get('email').hasError('email')">
          You must enter a valid email address.
        </mat-error>
      </mat-form-field>
    </mat-card-content>
    <mat-card-actions>
      <button mat-raised-button color="primary" (click)="onSubmit()">
        Submit
      </button>
    </mat-card-actions>
  </mat-card>
</form>

<form [formGroup]="form" *ngIf="data">
  <mat-card>
    <mat-card-content>
      <div class="actions">
        <button (click)="onSubmit()" mat-raised-button color="primary">
          Save changes
        </button>
        <button (click)="onDelete()" mat-raised-button color="warn">
          Reset dashboard
        </button>
        <a
          [routerLink]="['/screen/details']"
          [queryParams]="{ id: this.data.id }"
          mat-flat-button
          color="link"
        >
          Cancel
        </a>
      </div>
      <app-screen-preview [tiles]="data.tiles" edit="true"></app-screen-preview>
    </mat-card-content>
  </mat-card>
</form>
<mat-spinner *ngIf="!data"></mat-spinner>

<a [routerLink]="['/table/create']" mat-raised-button color="primary">
  <mat-icon>add</mat-icon>
  Create table
</a>
<mat-form-field>
  <mat-label>Filter</mat-label>
  <input (change)="updateData()" matInput [formControl]="filter" />
</mat-form-field>
<mat-spinner *ngIf="!data"></mat-spinner>

<table mat-table [dataSource]="data" matSort (matSortChange)="onSort($event)" *ngIf="data">
  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
    <td mat-cell *matCellDef="let row">{{row.name}}</td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="columns"></tr>
  <tr mat-row *matRowDef="let row; columns: columns" (click)="details(row)"></tr>
</table>

<mat-paginator [length]="count"
               (page)="onPage($event)"
               [pageSize]="20"
               [pageIndex]="page"
               *ngIf="data">
</mat-paginator>
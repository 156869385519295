<table mat-table [dataSource]="dataSource">
  <ng-container *ngFor="let column of table.columns;" [matColumnDef]="column.name">
    <th mat-header-cell *matHeaderCellDef>
      {{column.name}}
      <button type="button" (click)="deleteColumn(column)" mat-mini-fab color="warn">
        <mat-icon>delete</mat-icon>
      </button>
    </th>
    <td mat-cell *matCellDef="let row">{{row[column.name]}}</td>
  </ng-container>

  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef>Actions</th>
    <td mat-cell *matCellDef="let row">
      <button type="button" mat-mini-fab (click)="deleteRow(row)" color="warn">
        <mat-icon>delete</mat-icon>
      </button>
      <button class="row-edit" type="button" (click)="editRow(row)" mat-mini-fab color="accent">
        <mat-icon>create</mat-icon>
      </button>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="columnNames"></tr>
  <tr mat-row *matRowDef="let row; columns: columnNames"></tr>
</table>
<button type="button" (click)="addColumn()" mat-raised-button color="primary">
  <mat-icon>add</mat-icon> Add column
</button>
<button type="button" (click)="addRow()" mat-raised-button color="primary">
  <mat-icon>add</mat-icon> Add row
</button>
<mat-card>
  <div class="row">
    <div class="col">
      <mat-card-title>Building Area Calculator</mat-card-title>
    </div>
    <div class="col">
      <button
        id="angle-button"
        mat-raised-button
        color="primary"
        type="button"
        (click)="changeAngle()"
        class="btn btn-secondary"
      >
        {{ angleText }}&deg;
      </button>
      <button
        mat-raised-button
        color="primary"
        type="button"
        (click)="zoomFit()"
        class="btn btn-secondary"
      >
        <i class="material-icons">crop_free</i>
      </button>
      <button
        mat-raised-button
        color="warn"
        type="button"
        (click)="undo()"
        class="btn btn-secondary"
      >
        <i class="material-icons">undo</i>
      </button>
    </div>
  </div>
  <div #container (window:resize)="onResize()">
    <canvas
      #canvas
      (click)="onClick($event)"
      [width]="width"
      [height]="height"
    ></canvas>
    <p>{{ prompt }}</p>
    <form (ngSubmit)="onSubmit()" [style.opacity]="showForm ? '1' : '0'">
      <mat-form-field>
        <input
          #inputElement
          matInput
          type="number"
          min="1"
          [formControl]="control"
          required
        />
      </mat-form-field>
    </form>
  </div>
</mat-card>

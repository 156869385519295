export enum Locations {
  UpperThird = 0,
  MiddleThird,
  LowerThird,
  LeftColumn,
  MiddleColumn,
  RightColumn,
  TopLeft,
  TopMiddle,
  TopRight,
  MiddleLeft,
  Middle,
  MiddleRight,
  BottomLeft,
  BottomMiddle,
  BottomRight
}

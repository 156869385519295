<h1 *ngIf="configuration.title">{{configuration.title}}</h1>
<table mat-table [dataSource]="dataSource">
  <ng-container *ngFor="let column of table.columns;" [matColumnDef]="column.name">
    <th [class.hidden]="!configuration.showHeaders" mat-header-cell *matHeaderCellDef>
      {{column.name}}
    </th>
    <td mat-cell *matCellDef="let row">{{row[column.name]}}</td>
  </ng-container>

  <tr mat-header-row [class.dense]="configuration.dense" *matHeaderRowDef="columnNames"></tr>
  <tr mat-row [class.dense]="configuration.dense" *matRowDef="let row; columns: columnNames"></tr>
</table>